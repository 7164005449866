export const serverApi = 'https://api.innerlogic.com';
export const staginApi = 'https://stg-api.innerlogic.ca';
export const localApi = 'http://127.0.0.1:8000';
// Publishable key
export const StripeKey = 'pk_test_bowWtykmtAwZxvD0bvdXEycG00LczLxeDB';
export const currentApi = serverApi;
export const SentryDSN_DEV = '';
export const SentryDSN_PROD = '';
export const SentryDSN_STG = '';
export const Environment = 'Production';
export const config = {
  loginapi: `${currentApi}/api/account`,
  authapi: `${currentApi}/api/rest-auth`,
  surveyapi: `${currentApi}/api/survey`,
  appapi: `${currentApi}/api/organization`,
  website: `${currentApi}/api/web`,
};
export default config;

